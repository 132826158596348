@media screen and (min-width: 1200px) {
.mainContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
}
.subContainer {
  width: 90%;
  height: 100%;
}
.cwsTextmoduleSubtitle {
  height: 90px;
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 700;
  letter-spacing: 4.5px;
  margin: 0;
  padding-top: 40px;
  font-size: 18px;
}
 .cwsTextmoduleTitle {
    height: 90px;
    width: 100%;
    font-size: 55px;
    font-weight: 700;
    color: #1f5abc;
}

.cwsTextmoduleContentWrapper{
    font-size: 16px;
    line-height: 1.625em;
}
}
@media screen and (max-width: 1199px) {
  .mainContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;
  }
  .subContainer {
    width: 90%;
    height: 100%;
  }
  .cwsTextmoduleSubtitle {
    height: 90px;
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 700;
    letter-spacing: 4.5px;
    margin: 0;
    padding-top: 40px;
    font-size: 18px;
  }
   .cwsTextmoduleTitle {
      height: 90px;
      width: 100%;
      font-size: 55px;
      font-weight: 700;
      color: #1f5abc;
  }
  
  .cwsTextmoduleContentWrapper{
      font-size: 16px;
      line-height: 1.625em;
  }

}
@media screen and (max-width: 699px) {
  .mainContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;
  }
  .subContainer {
    width: 90%;
    height: 100%;
  }
  .cwsTextmoduleSubtitle {
    height: 90px;
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 700;
    letter-spacing: 4.5px;
    margin: 0;
    padding-top: 40px;
    font-size: 18px;

    justify-content: center;

  }
   .cwsTextmoduleTitle {
      height: 90px;
      width: 100%;
      font-size: 35px;
      font-weight: 700;
      color: #1f5abc;
      display: flex;
      justify-content: flex-start;
      align-items: center;
  }
  
  .cwsTextmoduleContentWrapper{
      font-size: 16px;
      line-height: 1.625em;
  }
}