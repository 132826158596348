@media screen and (min-width: 1200px) {

.mainContainerChoose {
  width: 100%;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.subContainerChoose {
  height: 8rem;
  width: 94%;
  background-color: #1f5abc;
  border-radius: 15px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.cwsTextmoduleTitle {
  height: 100%;
  width: 40%;
  color: white;
  font-size: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}
.cwsTextmodulePrice {
  height: 100%;
  width: 30%;
  color: white;
  font-size: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 20px;
}
.topValue {
  font-size: 2.5rem;
  font-weight: 600;
  font-family:Arial, Helvetica, sans-serif;
}
.lowerValue {
  font-size: 0.8rem;
  font-weight: 600;
}
}
@media screen and (max-width: 1199px) {
  .mainContainerChoose {
    width: 100%;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .subContainerChoose {
    height: 6rem;
    width: 94%;
    background-color: #1f5abc;
    border-radius: 15px;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .cwsTextmoduleTitle {
    height: 100%;
    width: 40%;
    color: white;
    font-size: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
  }
  .cwsTextmodulePrice {
    height: 100%;
    width: 30%;
    color: white;
    font-size: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 20px;
  }
  .topValue {
    font-size: 2rem;
    font-weight: 600;
  }
  .lowerValue {
    font-size: 13px;
    font-weight: 600;
  
  }
}
@media screen and (max-width: 699px) {
  .mainContainerChoose {
    width: 100%;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .subContainerChoose {
    height: 5.5rem;
    width: 94%;
    background-color: #1f5abc;
    border-radius: 15px;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .cwsTextmoduleTitle {
    height: 100%;
    width: 40%;
    color: white;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
  }
  .cwsTextmodulePrice {
    height: 100%;
    width: 30%;
    color: white;
    font-size: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 20px;
  }
  .topValue {
    font-size: 1.3rem;
    font-weight: 600;
  }
  .lowerValue {
    font-size: 9px;
    font-weight: 600;
  
  }
}