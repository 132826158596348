@media screen and (min-width: 1200px) {
.mainContainer {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.subContainer {
  height: 50%;
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #1f5abc;
  background-color: #d2eaff;
  flex-direction: column;
}
.text {
  color: #1f5abc;
}
.buyNowButton {
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  -webkit-transition: color 0.3s, background-color 0.3s;
  transition: color 0.3s, background-color 0.3s;
  padding: 16px 35px 17px;
  border-width: 3px;
  border-style: solid;
  color: #ffffff;
  border-color: rgb(31, 90, 188);
  background-color: rgba(31, 90, 188, 1);
  cursor: pointer;
  text-decoration: none;
}
}
@media screen and (max-width: 1199px) {
  .mainContainer {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .subContainer {
    height: 50%;
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid #1f5abc;
    background-color: #d2eaff;
    flex-direction: column;
  }
  .text {
    color: #1f5abc;
  }
  .buyNowButton {
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 15px;
    border-radius: 15px;
    -webkit-transition: color 0.3s, background-color 0.3s;
    transition: color 0.3s, background-color 0.3s;
    padding: 16px 35px 17px;
    border-width: 3px;
    border-style: solid;
    color: #ffffff;
    border-color: rgb(31, 90, 188);
    background-color: rgba(31, 90, 188, 1);
    cursor: pointer;
    text-decoration: none;
  }
}
@media screen and (max-width: 699px) {
  .mainContainer {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .subContainer {
    height: 50%;
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid #1f5abc;
    background-color: #d2eaff;
    flex-direction: column;
    padding: 15px;
  }
  .text {
    color: #1f5abc;
    font-size: 15px;
  }
  .buyNowButton {
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 15px;
    border-radius: 15px;
    -webkit-transition: color 0.3s, background-color 0.3s;
    transition: color 0.3s, background-color 0.3s;
    padding: 16px 35px 17px;
    border-width: 3px;
    border-style: solid;
    color: #ffffff;
    border-color: rgb(31, 90, 188);
    background-color: rgba(31, 90, 188, 1);
    cursor: pointer;
    text-decoration: none;
  }
}