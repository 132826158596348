.pageFooter{
    background-color:#142b5f;
    height: 90vh;
    width: 100%;
    margin-bottom: 30px;

}

.footerHeader{
    display: flex;
    flex-direction: column;
    width: 20rem;
    padding-left: 3em;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#bbd0ff;
    
}
.footerRight{
    height: 400px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 2em;
    width: 15em;
}
.footerMain{
    display: flex;
    flex-direction: row;
}
.mainContainer{
    margin-left: 5.5rem;
    display: flex;
    flex-direction: row;
    margin-top: 4rem;
}
.mainHeading{
    font-size: 1.7rem;
    color:white;
    font-weight: 600;
}
