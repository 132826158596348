@media screen and (min-width: 1200px) {
  .App {
    text-align: center;
  }
  .header {
    padding-top: 10px;
    padding-bottom: 10px;
    background-image: url(https://streamingfamous.com/wp-content/uploads/2019/07/bg_00.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    /* height: 28vh; */
  }
  .topBarWrapper .top-bar-inner-wrapper {
    display: block !important;
  }
  .headerTop {
    height: 45px;
    width: 100%;
    background-color: rgb(27 46 125);
    display: flex;
  }
  .textLooking {
    color: rgb(197 207 255);
    font-size: 15px;
  }
  .topBarLinksWrapper {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .secondContainer {
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 50px;
  }
  .secondSubContainer {
    height: 50px;
    width: 100%;
    justify-content: flex-start;
    display: flex;
    align-items: center;
  }
  .logo-default {
    cursor: pointer;
  }
  .homeContainer {
    height: 100%;
    width: 25%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
  }
  .secondMainContainer {
    width: 75%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 90px;
  }
  .homeContainer {
    color: white;
    font-size: 17px;
    line-height: initial;
    font-weight: 600;
    cursor: pointer;
  }
  .fa-home:before {
    content: "\f015";
  }
  *,
  *:before,
  *:after {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .mayhemContainer {
    color: rgb(197 207 255);
    margin-left: 180px;
  }
}
@media screen and (max-width: 1199px) {
  .App {
    text-align: center;
  }
  .header {
    padding-top: 10px;
    padding-bottom: 10px;
    background-image: url(https://streamingfamous.com/wp-content/uploads/2019/07/bg_00.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    /* height: 28vh; */
  }
  .topBarWrapper .top-bar-inner-wrapper {
    display: block !important;
  }
  .headerTop {
    height: 45px;
    width: 100%;
    background-color: rgb(27 46 125);
    display: flex;
  }
  .textLooking {
    color: rgb(197 207 255);
    font-size: 15px;
    padding-left: 40px;
  }
  .topBarLinksWrapper {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .secondContainer {
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 50px;
  }
  .secondSubContainer {
    height: 50px;
    width: 100%;
    justify-content: flex-start;
    display: flex;
    align-items: center;
  }
  .logo-default {
    cursor: pointer;
  }
  .homeContainer {
    height: 100%;
    width: 25%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
  }
  .secondMainContainer {
    width: 75%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 90px;
  }
  .homeContainer {
    color: white;
    font-size: 17px;
    line-height: initial;
    font-weight: 600;
    cursor: pointer;
  }
  .fa-home:before {
    content: "\f015";
  }
  *,
  *:before,
  *:after {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .mayhemContainer {
    color: rgb(197 207 255);
    padding-left: 250px;

  }
}
@media screen and (max-width: 699px) {
  .headerTop {
    height: 80px;
    background-color: rgb(27 46 125);
    display: flex;
  }
  .textLooking {
    color: rgb(197 207 255);
    font-size: 0.9rem;
    padding: 10px;
  }
  .topBarLinksWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .secondContainer {
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 50px;
  }

  .mayhemContainer {
    color: rgb(197 207 255);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 55px;
  }
}
