@media screen and (min-width: 1200px) {
  .mainContainer {
    height: 45rem;
    width: 100%;
    background-color: #d2eaff;
    display: flex;
    flex-direction: row;
    margin-top: 12rem;
    margin-bottom: 2rem;
  }
  .card {
    height: 100%;
    width: 25%;
    background-color: #d2eaff;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .imageContainer {
    height: 30vh;
    width: 100%;
  }
  .headingContainer {
    height: 14vh;
    width: 73%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 25px;
    font-weight: 600;
  }
  .comingSoon {
    padding-top: 10px;
    width: 73%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    font-weight: 600;
    color: white;
    transform: rotate(-22deg);
  }
  .text {
    height: 40%;
    width: 73%;

    font-size: 18px;
    line-height: 28px;
  }
  .cardNew {
    height: 53rem;
    width: 25%;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -140px;
    cursor: move;
  }
  .headingContainer2 {
    margin-top: 80px;
    height: 14vh;
    width: 73%;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 25px;
    font-weight: 600;
  }
  .buyNowButton {
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 15px;
    border-radius: 15px;
    -webkit-transition: color 0.3s, background-color 0.3s;
    transition: color 0.3s, background-color 0.3s;
    padding: 16px 35px 17px;
    border-width: 3px;
    border-style: solid;
    color: #ffffff;

    cursor: pointer;
    margin-bottom: 15px;
  }
  .chooseText {
    color: rgba(31, 90, 188, 1);
    font-size: 20px;
    font-weight: bold;
    border-bottom-color: rgba(31, 90, 188, 1);
    border-bottom-width: 2;
  }
}
@media screen and (max-width: 1199px) {
  .mainContainer {
    height: 260rem;
    width: 100%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15rem;
  }

  .imageContainer {
    height: 30vh;
    width: 100%;
  }

  .comingSoon {
    width: 73%;
    display: flex;
    justify-content: center;
    font-size: 25px;
    font-weight: 600;
    color: white;
    transform: rotate(-22deg);
  }
  .text {
    height: 40%;
    width: 73%;
    font-size: 18px;
    line-height: 28px;
  }
  .cardNew {
    height: 50rem;
    width: 50%;
    background-color: white;
    border: 1px solid #d2eaff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: move;
    margin-top: 3rem;
  }
  .headingContainer2 {
    margin-top: 4rem;
    height: 14vh;
    width: 73%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 25px;
    font-weight: 600;
  }
  .buyNowButton {
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 15px;
    border-radius: 15px;
    -webkit-transition: color 0.3s, background-color 0.3s;
    transition: color 0.3s, background-color 0.3s;
    padding: 16px 35px 17px;
    border-width: 3px;
    border-style: solid;
    color: #ffffff;
    cursor: pointer;
    margin-bottom: 15px;
  }
  .chooseText {
    color: rgba(31, 90, 188, 1);
    font-size: 20px;
    font-weight: bold;
    border-bottom-color: rgba(31, 90, 188, 1);
    border-bottom-width: 2;
  }
}
@media screen and (max-width: 699px) {
  .mainContainer {
    height: 220rem;
    width: 100%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;

  }

  .imageContainer {
    height: 30vh;
    width: 100%;
  }
  .comingSoon {
    width: 73%;
    display: flex;
    justify-content: center;
    font-size: 25px;
    font-weight: 600;
    color: white;
    transform: rotate(-22deg);
    margin-top: -0.1rem;

  }
  .text {
    height: 40%;
    width: 73%;
    font-size: 18px;
    line-height: 28px;
    margin-top: 90px;
  }
  .cardNew {
    height: 50rem;
    width: 90%;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: move;
  }
  .headingContainer2 {
    height: 14vh;
    width: 73%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 25px;
    font-weight: 600;
  }
}
