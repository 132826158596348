@media screen and (min-width: 1200px) {
.mainContainerChoose {
    height: 120px;
    width: 100%;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .subContainerChoose {
    height: 100px;
    width: 94%;
    border:3px solid  #1f5abc;
    border-radius: 15px;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .cwsTextmoduleTitle {
    height: 100%;
    width: 40%;
    color: #1f5abc;
    font-size: 3rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 1199px) {
  .mainContainerChoose {
    height: 120px;
    width: 100%;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .subContainerChoose {
    height: 100px;
    width: 94%;
    border:3px solid  #1f5abc;
    border-radius: 15px;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .cwsTextmoduleTitle {
    height: 100%;
    width: 40%;
    color: #1f5abc;
    font-size: 2rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 699px) {
  .mainContainerChoose {
    height: 120px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .subContainerChoose {
    height: 100px;
    width: 94%;
    border:3px solid  #1f5abc;
    border-radius: 15px;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .cwsTextmoduleTitle {
    height: 100%;
    width: 100%;
    color: #1f5abc;
    font-size: 2rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
  