@media screen and (min-width: 1200px) {
  .mainContainerCard {
    height: 38rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 70px;
    border-radius: 15px;
  }
  .subContainerCard {
    height: 100%;
    width: 90%;
    background-color: rgb(210 234 255);
    display: flex;
    flex-direction: row;
    border-radius: 15px;
    justify-content: space-evenly;
    align-items: center;
  }
  .CardContainer {
    height: 100%;
    width: 33.3%;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .topContainer {
    height: 120px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .CardSubContainer {
    height: 90%;
    width: 80%;
    padding-left: 20px;
  }
  .tryUsContainer {
    height: 45px;
    padding: 20px;
    border: 2px solid rgb(166 213 255);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
  .tryUsText {
    font-size: 1rem;
  }
  .cost {
    height: 120px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 49px;
    font-weight: 700;
  }
  .tickContainer {
    height: 65px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .line {
    background-color: rgb(152 206 255);
    height: 1px;
    width: 100%;
    margin-top: 18px;
  }
  .tickSubContainer {
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .lookingFont {
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: center;
  }
  .clickFont {
    font-size: 15px;
    font-weight: 500;
    display: flex;
    justify-content: center;
  }
  .buyNowButtonContainer {
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .buyNowButton {
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 15px;
    border-radius: 15px;
    -webkit-transition: color 0.3s, background-color 0.3s;
    transition: color 0.3s, background-color 0.3s;
    padding: 16px 35px 17px;
    border-width: 3px;
    border-style: solid;
    color: #ffffff;
    border-color: rgb(31, 90, 188);
    background-color: rgba(31, 90, 188, 1);
    cursor: pointer;
  }
  .contactUsButton {
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 15px;
    border-radius: 15px;
    -webkit-transition: color 0.3s, background-color 0.3s;
    transition: color 0.3s, background-color 0.3s;
    padding: 16px 35px 17px;
    border-width: 3px;
    border-style: solid;
    color: #9d5f36;
    background-color: #ffe27a;
    border-color: #ffe27a;
    cursor: pointer;
  }
  .buyNowButton:hover {
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 15px;
    border-radius: 15px;
    -webkit-transition: color 0.3s, background-color 0.3s;
    transition: color 0.3s, background-color 0.3s;
    padding: 16px 35px 17px;
    border-width: 3px;
    border-style: solid;
    color: rgba(31, 90, 188, 1);
    border: 2px solid rgb(132, 223, 236);
    background-color: white;
    cursor: pointer;
  }
  .termText {
    font-size: 14px;
    height: 20px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    font-weight: 600;
  }
  .iconArrow {
    margin-left: 5px;
    position: absolute;
  }
}
@media screen and (max-width: 1199px) {
  .mainContainerCard {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 70px;
    border-radius: 15px;
  }
  .subContainerCard {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    justify-content: space-evenly;
    align-items: center;
  }
  .CardContainer {
    height: 100%;
    width: 100%;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
  }
  .topContainer {
    height: 120px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .CardSubContainer {
    height: 90%;
    width: 80%;
    padding-left: 20px;
  }
  .tryUsContainer {
    height: 45px;
    padding: 20px;
    border: 2px solid rgb(166 213 255);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
  .tryUsText {
    font-size: 1rem;
  }
  .cost {
    height: 120px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 49px;
    font-weight: 700;
  }
  .tickContainer {
    height: 65px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .line {
    background-color: rgb(152 206 255);
    height: 1px;
    width: 100%;
    margin-top: 18px;
  }
  .tickSubContainer {
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .lookingFont {
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: center;
  }
  .clickFont {
    font-size: 15px;
    font-weight: 500;
    display: flex;
    justify-content: center;
  }
  .buyNowButtonContainer {
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .buyNowButton {
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 15px;
    border-radius: 15px;
    -webkit-transition: color 0.3s, background-color 0.3s;
    transition: color 0.3s, background-color 0.3s;
    padding: 16px 35px 17px;
    border-width: 3px;
    border-style: solid;
    color: #ffffff;
    border-color: rgb(31, 90, 188);
    background-color: rgba(31, 90, 188, 1);
    cursor: pointer;
  }
  .contactUsButton {
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 15px;
    border-radius: 15px;
    -webkit-transition: color 0.3s, background-color 0.3s;
    transition: color 0.3s, background-color 0.3s;
    padding: 16px 35px 17px;
    border-width: 3px;
    border-style: solid;
    color: #9d5f36;
    background-color: #ffe27a;
    border-color: #ffe27a;
    cursor: pointer;
  }
  .termText {
    font-size: 14px;
    height: 20px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    font-weight: 600;
  }
  .iconArrow {
    position: absolute;
    margin-left: 5px;
  }
}
@media screen and (max-width: 699px) {
  .mainContainerCard {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 70px;
    border-radius: 15px;
  }
  .subContainerCard {
    height: 100%;
    width: 90%;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    justify-content: space-evenly;
    align-items: center;
  }
}
